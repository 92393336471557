<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar title="Your Account Manager" :showbroker="false" :showaction="false" :showback="true" />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="false"/>
      </div>

      <div class="col-span-8">
        <div
          class="flex w-full bg-img vx-row no-gutter items-center justify-center"
          id="page-login"
        >
          <div class="grid grid-cols-2 gap-4">
            <div class="m-20">
              <img
                alt="name"
                :src="brokerImage()"
                class="h-36 w-36 rounded-full object-center object-[59%_-4px]"
              />

              <h1 data-v-b75de274="" class="text-6xl oeno_portfolio_title">
                {{ $store.state.user.brokerName }}
              </h1>
              <h1
                data-v-b75de274=""
                class="text-4xl oeno_portfolio_title_small"
              >
                Wine Broker
              </h1>

              <h1
                data-v-b75de274=""
                class="text-4xl oeno_portfolio_title_small pt-10"
              >
                Ask me a question
              </h1><br/>

              <md-field class="md-field-small">
                <md-textarea v-model="brokermessage" class=" border-2 border-black rounded-3xl"></md-textarea>
              </md-field>

              <button
                type="submit"
                @click="submitForm()"
                class="w-48 h-10 oeno_button_black"
                >{{ updateStatus ? "Submitting...." : "Submit" }}</button
              >

              <div class="grid grid-cols-3 gap-4 w-40 pt-20">
                <div v-if="$store.state.user.brokerLinkedIn != ''">
                  <a :href="$store.state.user.brokerLinkedIn" target="_new"
                    ><img src="@/assets/linkedin.png" class="w-10"
                  /></a>
                </div>
                <div v-if="$store.state.user.brokerEmail != ''">
                  <a :href="'mailto:' + $store.state.user.brokerEmail"
                    ><img src="@/assets/email.png" class="w-10"
                  /></a>
                </div>
                <div :v-if="$store.state.user.brokerPhone !== ''">
                  <a :href="'tel:' + $store.state.user.brokerPhone"
                    ><img src="@/assets/phone.png" class="w-10"
                  /></a>
                </div>
              </div>
            </div>

            <div class="m-20">
              <span style="white-space: pre-wrap">{{
                $store.state.user.brokerBio
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

import { HTTP } from "@/axios.js";

export default {
  data() {
    return {
      showmenu: false,
      updateStatus: false,
      brokermessage: "",
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },

  mounted() {},
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
  },
  methods: {
    brokerImage() {
      var brokerimageurl = process.env.VUE_APP_BROKER_IMAGE_PATH;
      if (this.$store.state.user.brokerImage != "") {
        brokerimageurl =
          process.env.VUE_APP_BROKER_IMAGE_PATH +
          this.$store.state.user.brokerImage;
      }
      return brokerimageurl;
    },
    submitForm() {
      this.updateStatus = true;

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.post(
        "/api/broker-message",
        { brokermessage: this.brokermessage },
        { headers: headers }
      )
        .then((response) => {
          this.updateStatus = false;

          if (response.data.statuscode == 200) {
            this.brokermessage = "";
          } else {
            this.$notify({
              group: "oeno",
              title: "Broker Message Error",
              text: "Sorry there seems to have been an error processing your password reset, please try again later.",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.resettingpass = false;

          this.$notify({
            group: "oeno",
            title: "Broker Message Error",
            text: "Sorry there seems to have been an error processing your password reset, please try again later.",
            type: "error",
          });

          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.circletag {
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 50%;
  background-color: #fff;
  line-height: 100px;
  text-align: center;
}
.circletag img {
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}
</style>
